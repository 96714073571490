<template>
  <div class="showcase pa-6 pa-md-10">
    <h1 class="mb-8 mb-md-11">Contact Us</h1>

    <v-container>
      <v-row class="form pa-2 pa-md-15">
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column justify-space-between"
        >
          <div>
            <h2 class="info-header mb-5 mb-md-9">{{ $t("contact.title") }}</h2>
            <p class="info-para mb-9">
              {{ $t("contact.content") }}
            </p>
            <div class="info-icon mb-7">
              <v-icon size="24">mdi-map-marker-radius </v-icon>
              <p>{{ $t("contact.location") }}</p>
            </div>
            <div class="info-icon mb-7">
              <v-icon size="24">mdi-clock-time-seven-outline </v-icon>
              <p>{{ $t("contact.workTime") }}</p>
            </div>
            <div class="info-icon mb-7">
              <v-icon size="24">mdi-email </v-icon>
              <div>
                <p>contactus@appfeedy.com</p>
                <p class="text--disabled">
                  {{ $t("contact.forGeneralQuestions") }}
                </p>
              </div>
            </div>
            <div class="info-icon mb-7">
              <v-icon size="24">mdi-email </v-icon>
              <div>
                <p>support@appfeedy.com</p>
                <p class="text--disabled">
                  {{ $t("contact.forTechnicalIssues") }}
                </p>
              </div>
            </div>
          </div>
          <p class="text--disabled mb-0 pr-0 pr-md-10 font-13">
            {{ $t("contact.fyi") }}
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <h2 class="info-header text-center mb-9">
            {{ $t("contact.form.title") }}
          </h2>
          <v-text-field
            :label="$t('contact.form.name')"
            outlined
          ></v-text-field>
          <v-text-field
            :label="$t('contact.form.email')"
            outlined
          ></v-text-field>
          <v-text-field
            :label="$t('contact.form.subject')"
            outlined
          ></v-text-field>
          <v-textarea
            outlined
            name="input-7-4"
            :label="$t('contact.form.message')"
          ></v-textarea>
          <div class="btn-container">
            <v-btn class="btn-main w-140 h-50 mr-4">{{
              $t("contact.form.send")
            }}</v-btn>

            <div class="d-flex align-center">
              <p
                class="text--disabled mb-0 mr-2 mr-md-7 font-13"
                style="max-width: 162px"
              >
                {{ $t("contact.form.screenshot") }}
              </p>
              <v-btn
                @click="$refs.imgFile.click()"
                fab
                dark
                small
                color="#7566fd"
                class="ma-2"
              >
                <v-icon size="32">mdi-plus</v-icon>
                <input
                  ref="imgFile"
                  type="file"
                  name="file"
                  hidden
                  accept="image/*, image/jpeg, image/jpg"
                />
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
